<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Lista de Fatura Extrato</h3>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Pesquisar..."
                clearable
              />
            </div>

          </div>

          <div>
            <!--navegação-->
            <ul class="nav nav-tabs" >
              
              <li 
                class="nav-item"
              >
                <a 
                  class="nav-link" 
                  href="#"
                  @click.prevent="selecionaUltimoPaginaAnterior()"
                  :class="{disabled: fatura_nav.pagina_selecionada == 0}"
                >
                  	&#60;&#60;
                </a>
              </li>

              <li 
                class="nav-item"
                v-for="(fatura, index) in faturasNaPagina" 
                :key="fatura.id || index"
              >
                <a 
                  class="nav-link" 
                  :class="{ active: index === fatura_nav.item_selecionado, disabled: !fatura.id }" 
                  href="#"
                  @click.prevent="selecionarFatura(fatura, index)"
                >
                   {{ fatura.id ? formatDate(fatura.vencimentofatura) : " " }}
                </a>
              </li>
              
              <li 
                class="nav-item"
              >
                <a 
                  class="nav-link" 
                  href="#"
                  :class="{disabled: fatura_nav.pagina_max <= fatura_nav.pagina_selecionada }"
                  @click.prevent="selecionaPrimeiroProximaPagina()"
                >
                  	&#62;&#62;
                </a>
              </li>
            </ul>
          </div>

          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="faturaextratos"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="vencimento"
              min-width="100px"
              prop="vencimento"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{ formatDate(scope.row.vencimento) }}
              </template>
            </el-table-column>
            <el-table-column
              label="Cod."
              min-width="100px"
              prop="contrato.codigo"
              sortable="custom"
            />
            <el-table-column
              label="parcela"
              min-width="125px"
              prop="parcela"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{ scope.row.parcela }}/{{ scope.row.parcelatotal }}
              </template>
            </el-table-column>
            <el-table-column
              label="valor"
              min-width="125px"
              prop="valor"
              sortable="custom"
            >
              <template slot-scope="scope"> R$ {{ scope.row.valor }} </template>
            </el-table-column>
            <el-table-column
              label="Tipo Lancamento"
              min-width="125px"
              prop="tipolancamento"
              sortable="custom"
            >
              <template slot-scope="scope">
                <span v-if="scope.row">
                  {{ tiposlancamentos[scope.row.tipolancamento] }}
                </span>
              </template>
            </el-table-column>

            
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Exibindo de {{ total ? from + 1 : 0 }} até {{ to }} de um total de
              {{ total }} registros.

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import Vue from "vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      tiposlancamentos: { 1: "Parcela Seguro", 2: "Pró-Labore" },
      query: null,
      faturaextratos: [],
      faturas: [],
      navitem_at: 0,
      navitem_filtros: [{}],
      selectedRows: [],
      sort: "-created_at",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      
      total: 0,
      fatura_total: 0,
      fatura_nav:{
        id_selecionado:0,
        item_por_pagina:4,
        item_selecionado:1,
        pagina_selecionada:1,
        pagina_max:0,
      },
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    // Faturas filtradas pela página selecionada
    faturasNaPagina() {
      const inicio = (this.fatura_nav.pagina_selecionada - 1) * this.fatura_nav.item_por_pagina;
      const fim = inicio + this.fatura_nav.item_por_pagina;
      const faturasPagina = this.faturas.slice(inicio, fim);

      // Adiciona elementos vazios se necessário
      while (faturasPagina.length < this.fatura_nav.item_por_pagina) {
        faturasPagina.push({}); // Elemento vazio
      }

      return faturasPagina;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr + 'T00:00');
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("pt-BR", options);
    },

    formatDateTime(dateStr) {
      const date = new Date(dateStr);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return date.toLocaleDateString("pt-BR", options);
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      //fatura
      try {
        let params = {
          sort: 'vencimentofatura',
          filter: {},
          page: {
            number: 1,
            size: 25,
          },
        };
        await this.$store.dispatch("faturas/list", params).then(() => {
          this.faturas = this.$store.getters["faturas/list"];
          this.fatura_total = this.$store.getters["faturas/listTotal"];
          this.fatura_nav.pagina_max=Math.ceil(this.fatura_total/this.fatura_nav.item_por_pagina);

          var fila=0;
          // Adiciona o número da página para cada elemento
          this.faturas = this.faturas.map((fatura, index) => {
            fila++;
            fatura.pagina = Math.floor(index / this.fatura_nav.item_por_pagina) + 1;
            fatura.fila = fila;
            return fatura;
          });

          //primeira ocorrencia de data maior que hoje
          const hoje = new Date();
          const proximaFatura = this.faturas.find(fatura => {
            const dataFatura = new Date(fatura.vencimentofatura);
            return dataFatura > hoje;
          });

          //preenche dados
          if (proximaFatura !== -1) {
            this.fatura_nav.id_selecionado=proximaFatura.id;
            this.fatura_nav.item_selecionado=proximaFatura.fila-(Math.floor(proximaFatura.fila/this.fatura_nav.item_por_pagina));
            this.fatura_nav.pagina_selecionada=Math.floor(proximaFatura.fila/this.fatura_nav.item_por_pagina)+1;
            
          } else {
            console.warn("Nenhuma fatura futura encontrada.");
            this.fatura_nav.item_selecionado = 0;
            this.fatura_nav.pagina_selecionada = this.fatura_nav.pagina_max; // Página final
          }
        });

        //carrega faturas
        this.getListFatura();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
      
    async getListFatura() {
      //faturaextrato
      //
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {fatura_id: this.fatura_nav.id_selecionado},
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "contrato,seguroincendio",
        };
        await this.$store.dispatch("faturaextratos/list", params).then(() => {
          this.faturaextratos = this.$store.getters["faturaextratos/list"];
          this.total = this.$store.getters["faturaextratos/listTotal"];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    selecionarFatura(fatura, index) {
      this.fatura_nav.item_selecionado = index;
      this.fatura_nav.id_selecionado = fatura.id;
      
      //carrega faturas
      this.getListFatura();
    },
    selecionaUltimoPaginaAnterior() {
      if (this.fatura_nav.pagina_selecionada > 1) {
        this.fatura_nav.pagina_selecionada -= 1;
        this.fatura_nav.item_selecionado = 0; // Reinicia o item selecionado
      }
    },
    selecionaPrimeiroProximaPagina() {
      const totalPaginas = Math.ceil(this.faturas.length / this.fatura_nav.item_por_pagina);
      if (this.fatura_nav.pagina_selecionada < totalPaginas) {
        this.fatura_nav.pagina_selecionada += 1;
        this.fatura_nav.item_selecionado = 0; // Reinicia o item selecionado
      }
    },

    editFaturaextrato(row) {
      this.$router.push({
        name: "Edit Faturaextrato",
        params: { id: row.id },
      });
    },
    async deleteFaturaextrato(row) {
      if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
        this.$notify({
          type: "danger",
          message:
            "You are not allowed not change data of default faturaextratos.",
        });
        return;
      }
      try {
        const confirmation = await swal.fire({
          title: `Delete this item?`,
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, keep it",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("faturaextratos/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Item deleted successfully.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },
    addFaturaextrato() {
      this.$router.push({ name: "Add Faturaextrato" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
