<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Lista de Locações</h3>
              <h4
                v-if="
                  dashboard[this.navitem_at] &&
                  [this.dashboard[this.navitem_at]['descricao']] != ''
                "
              >
                <small
                  >Exibindo
                  {{ this.dashboard[this.navitem_at]["descricao"] }}</small
                >
              </h4>
            </div>
            <div class="col-6 text-right">
              <base-button
                v-if="
                  preferenciaapis.erp_id != 0 && preferenciaapis.erp_id != null
                "
                type="info"
                icon
                size="sm"
                @click="baixarContrato()"
              >
                <span class="btn-inner--icon"><i class="fas fa-code"></i></span>
                <span class="btn-inner--text"
                  >Baixar do
                  <span v-if="preferenciaapis.erp_id == 1">Superlogica</span>
                  <span v-else-if="preferenciaapis.erp_id == 2">Imoview</span>
                  <span v-else>ERP</span>
                </span>
              </base-button>

              <base-button type="primary" icon size="sm" @click="addContrato()">
                <span class="btn-inner--icon"
                  ><i class="fas fa-house-user"></i
                ></span>
                <span class="btn-inner--text">Nova Locação</span>
              </base-button>
            </div>
          </div>

          <div class="container">
            <template v-for="(alerta, index) in dashboard">
              <base-alert
                :key="index"
                class="mt-4"
                dismissible
                type="warning"
                icon="fas fa-triangle-exclamation fa-3x"
                v-if="alerta.is_warning == true && alerta.result_qtd > 0"
              >
                <H4>ATENÇÃO!</H4>
                <p>{{ alerta.txt_warning }}</p>
                <base-button
                  class="ml-0"
                  type="secondary"
                  size="sm"
                  v-if="navitem_at != index"
                  @click="
                    navitem_at = index;
                    getList();
                  "
                >
                  Visualizar
                </base-button>
                <div v-else>
                  <i>Exibindo Contratos na listagem abaixo</i>
                </div>
              </base-alert>
            </template>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Pesquisar..."
                clearable
              />
            </div>
          </div>
          <template>
            <ul class="nav nav-tabs nav-fill">
              <li
                class="nav-item"
                v-for="(item, index) in dashboard"
                :key="index"
              >
                <a
                  v-bind:class="[
                    navitem_at == index ? 'active' : '',
                    'nav-link',
                    item.result_qtd > 0 || item.style == 'todos'
                      ? ''
                      : 'disabled',
                  ]"
                  v-if="item.is_warning == false || navitem_at == index"
                  href="#"
                  @click="
                    navitem_at = index;
                    getList();
                  "
                >
                  <badge
                    class="mr-2"
                    :type="item.style"
                    v-if="item.result_qtd > 0"
                    >{{ item.result_qtd }}
                  </badge>
                  {{ item.title }}</a
                >
              </li>
            </ul>
          </template>

          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="contratos"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="Código"
              min-width="90px"
              prop="codigo"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <span
                  :style="{
                    textDecoration: !['1', '2'].includes(
                      row.situcontrato_id.toString()
                    )
                      ? 'line-through'
                      : 'none',
                  }"
                >
                  {{ row.codigo }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="Imóvel" min-width="200px">
              <template v-slot="{ row }">
                <div>
                  <!--informações do contrato-->
                  <span
                    v-if="
                      !['1', '2'].includes(row.situcontrato_id.toString()) &&
                      (has_seguroAtivo(row.seguroincendio) ||
                        has_seguroContratando(row.seguroincendio))
                    "
                    class="badge badge-danger"
                    >CONTRATO ENCERRADO</span
                  >
                  <span
                    v-if="row.imovel"
                    :style="{
                      textDecoration: !['1', '2'].includes(
                        row.situcontrato_id.toString()
                      )
                        ? 'line-through'
                        : 'none',
                    }"
                  >
                    {{ row.imovel.codimovel ? row.imovel.codimovel : "##" }}:
                    {{ row.imovel.logradouro ? row.imovel.logradouro : "" }}
                    <span
                      v-if="
                        row.imovel.numero != 0 &&
                        row.imovel.numero != '' &&
                        row.imovel.numero != null
                      "
                    >
                      nº {{ row.imovel.numero }}
                    </span>
                    <span
                      v-if="
                        row.imovel.complemento != '' &&
                        row.imovel.complemento != null
                      "
                    >
                      ({{ row.imovel.complemento }})
                    </span>
                    - {{ row.imovel.bairro }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Seguro"
              min-width="125px"
              prop="seguroistatu.status"
            >
              <template v-slot="{ row }">
                <!--informações do contrato-->
                <span
                  v-if="
                    !['1', '2'].includes(row.situcontrato_id.toString()) &&
                    !has_seguroAtivo(row.seguroincendio) &&
                    !has_seguroContratando(row.seguroincendio)
                  "
                  class="badge badge-danger"
                  >CONTRATO ENCERRADO</span
                >
                <span
                  class="badge badge-danger"
                  v-if="
                    row.seg_deve_contratar == 0 &&
                    ['1', '2'].includes(row.situcontrato_id.toString())
                  "
                >
                  Seguro não deve ser Contratado
                </span>

                <!--informações das apólices-->
                <div>
                  <div class="ml-0">
                    <!--new code-->
                    <template>
                      <div>
                        <div
                          v-for="(seguro, index) in row.seguroincendio"
                          :key="index"
                        >
                          <!--DISPLAY APÓLICE CADASTRADA NO ERP-->
                          <div
                            class="ml-0"
                            v-if="
                              ['18'].includes(seguro.seguroistatu_id.toString())
                            "
                          >
                            <div>
                              <b>{{ seguro.seguroistatu.status }}</b>
                            </div>
                            <div>
                              <small
                                >Ativa até
                                {{
                                  formatDate(row.data_renovacao_externa)
                                }}</small
                              >
                            </div>
                            <div>
                              <small
                                ><div>
                                  <b>*Informações provenientes do ERP</b>
                                </div>
                                <div>
                                  <i
                                    >*A existencia da apólice e sua vigencia
                                    deve ser verificada.</i
                                  >
                                </div></small
                              >
                            </div>
                          </div>

                          <!--
                                      DISPLAY DA APÓLICE CONTRATADA SISPAD                                      
                                        Apólice ativa (4) 
                                        Sendo analisada seguradora (12) 
                                        Enviando porposta (3) 
                                        Em periodo de renovação (15)    
                                        ----                       
                                        Escolha forma de pagto (2)                             
                                        Orçamento Expirado (17)                             
                                        oRÇANDO (1)      
                                      -->
                          <div
                            class="my-4 p-2 rounded"
                            :style="{
                              border:
                                '1px solid ' +
                                getBorderStyle(seguro.seguroistatu_id),
                            }"
                            v-if="
                              ['1', '4', '3', '12', '15', '2', '17'].includes(
                                seguro.seguroistatu_id.toString()
                              )
                            "
                          >
                            <div>
                              <!--TITULO-->
                              <small :style="{ margin: '.1rem' }">
                                <b v-if="seguro.numeroapolice != 0"
                                  >APÓLICE {{ seguro.numeroapolice }}</b
                                >
                                <b v-else-if="seguro.numeroproposta != 0"
                                  >PROPOSTA {{ seguro.numeroproposta }}</b
                                >
                                <b v-else-if="seguro.numeroCalculo != 0"
                                  >ORÇAMENTO {{ seguro.numeroCalculo }}</b
                                >
                                <b
                                  v-else-if="
                                    ['1'].includes(
                                      seguro.seguroistatu_id.toString()
                                    )
                                  "
                                  >ORÇANDO</b
                                >
                              </small>
                            </div>

                            <div>
                              <small>
                                {{
                                  seguro.numeroapolice != 0
                                    ? "Ativa"
                                    : "Periodo"
                                }}
                                <span
                                  v-if="
                                    !is_vencido(seguro.datainicio) ||
                                    ['1', '2', '17'].includes(
                                      seguro.seguroistatu_id.toString()
                                    )
                                  "
                                >
                                  de {{ formatDate(seguro.datainicio) }}</span
                                >
                                até {{ formatDate(seguro.datafim) }}
                              </small>
                            </div>

                            <div
                              v-if="
                                ['4', '15'].includes(
                                  seguro.seguroistatu_id.toString()
                                )
                              "
                            >
                              <small>
                                {{ seguro.parcela_qtd }}x de R$
                                {{ seguro.parcela_valor }}
                              </small>
                            </div>

                            <div>
                              <!--baixar apólice com pdf-->
                              <span
                                v-if="seguro.apolicepdf == 1"
                                class="badge badge-default"
                                :style="{ margin: '.1rem', cursor: 'pointer' }"
                                content="Baixar Apólice"
                                @click="baixarArquivo(seguro)"
                              >
                                <i class="fas fa-file-pdf"></i>
                                Baixar Apólice {{ seguro.numeroapolice }}
                              </span>
                              <!--Baixar proposta-->
                              <span
                                v-else-if="seguro.propostapdf == 1"
                                class="badge badge-warning"
                                :style="{ margin: '.1rem', cursor: 'pointer' }"
                                content="Baixar Apólice"
                                @click="baixarArquivoProposta(seguro)"
                              >
                                <i class="fas fa-file-pdf"></i>
                                Baixar Proposta
                              </span>

                              <!-- Botão para Contratar Seguro -->
                              <div
                                class="ml-0"
                                v-if="
                                  ['2', '15'].includes(
                                    seguro.seguroistatu.id
                                  ) &&
                                  !is_vencido(seguro.validade) &&
                                  !is_vencido(seguro.datainicio)
                                "
                              >
                                <base-button
                                  class="ml-0 mt-2"
                                  type="warning"
                                  size="sm"
                                  @click="contratarSeguroincendio(seguro)"
                                >
                                  <i class="fas fa-handshake"></i>Clique para
                                  Contratar
                                </base-button>
                              </div>
                              <!-- Botão para alterar inicio do Seguro -->
                              <div
                                class="ml-0 mt-2"
                                v-else-if="
                                  ['2', '17'].includes(
                                    seguro.seguroistatu.id
                                  ) && is_vencido(seguro.datainicio)
                                "
                              >
                                <base-button
                                  class="ml-0"
                                  type="warning"
                                  size="sm"
                                  @click="AlterarDataInicio(seguro)"
                                >
                                  <i class="fas fa-calendar"></i>Alterar data
                                  Inicial
                                </base-button>
                              </div>
                              <!-- Botão para Renovar Orçamento -->
                              <div
                                class="ml-0 mt-2"
                                v-else-if="
                                  ['2', '17'].includes(
                                    seguro.seguroistatu.id
                                  ) &&
                                  is_vencido(seguro.validade) &&
                                  !is_vencido(seguro.datainicio)
                                "
                              >
                                <base-button
                                  class="ml-0"
                                  type="warning"
                                  size="sm"
                                  @click="Atualizarorcamento(seguro)"
                                >
                                  <i class="fas fa-house-fire"></i>Atualizar
                                  Orçamento
                                </base-button>
                              </div>

                              <!-- Botão para Cancelar apólice -->
                              <div
                                class="ml-0 mt-2"
                                v-else-if="
                                  (row.seg_deve_contratar == 0 ||
                                  ['3', '4'].includes(row.situcontrato_id.toString())) &&
                                  ['4', '15'].includes(
                                    seguro.seguroistatu.id
                                  ) && //ativa ou em renovação
                                  !is_vencido(seguro.datafim) && //esta vigente
                                  seguro.numeroapolice != 0 //possui apólice
                                "
                              >
                                <base-button
                                  class="ml-0"
                                  type="warning"
                                  size="sm"
                                  @click="deleteSeguroincendio(seguro)"
                                >
                                  <i class="fas fa-ban"></i>Cancelar Apólice
                                </base-button>
                              </div>
                            </div>
                          </div>
                          <!--fim display-->

                          <!--mostra status (somente contratos ativos/ e que devem ser contratados) || NAO MOSTRA ORÇAMENTO DISPENSADO-->
                          <div
                            v-else-if="
                              !['5'].includes(
                                seguro.seguroistatu_id.toString()
                              ) &&
                              ['1', '2'].includes(
                                row.situcontrato_id.toString()
                              ) &&
                              row.seg_deve_contratar == 1
                            "
                          >
                            {{ seguro.seguroistatu.status }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!--Botões de ação-->
                    <div
                      class="ml-0"
                      v-if="
                        !has_seguroAtivo(row.seguroincendio) &&
                        !has_seguroContratando(row.seguroincendio) &&
                        !has_seguroCotando(row.seguroincendio) &&
                        row.seg_deve_contratar == 1 &&
                        ['1', '2'].includes(row.situcontrato_id.toString())
                      "
                    >
                      <base-button
                        class="ml-0"
                        type="warning"
                        size="sm"
                        @click="orcarSegIContrato(row)"
                      >
                        <i class="fas fa-house-fire"></i>Clique para Cotar
                      </base-button>
                    </div>

                    <!--
                          <base-button class="ml-0" type="warning"  size="sm" 
                            @click="listaOrcamento(row)"
                            v-if="row.seguroincendio.length>1"
                          >
                            <i class="fas fa-handshake"></i>Opções de Contratação
                          </base-button>
                          -->
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column min-width="50px" align="right">
              <template v-slot="{ row }">
                <el-dropdown trigger="click" class="dropdown">
                  <span class="btn btn-sm btn-icon-only">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                  </span>
                  <el-dropdown-menu
                    class="dropdown-menu dropdown-menu-arrow show"
                    slot="dropdown"
                  >
                    <a
                      class="dropdown-header"
                      v-if="
                        (//cotar
                          !has_seguroAtivo(row.seguroincendio) &&
                        !has_seguroContratando(row.seguroincendio) &&
                        !has_seguroCotando(row.seguroincendio) &&
                        row.seg_deve_contratar == 1 &&
                        ['1', '2'].includes(row.situcontrato_id.toString())
                        ) || (//cancelar
                          has_seguroAtivo(row.seguroincendio)
                        )
                      "
                      ><b>Seguro:</b>
                    </a>

                    <!--opções apólice-->
                    <div
                      v-for="(seguro, index) in row.seguroincendio"
                      :key="index"
                    >
                      <a
                        class="dropdown-item"
                        style="cursor: pointer"
                        @click="deleteSeguroincendio(seguro)"
                        v-if="
                          ['4', '15'].includes(seguro.seguroistatu.id) && //ativa ou em renovação
                          !is_vencido(seguro.datafim) && //esta vigente
                          seguro.numeroapolice != 0 //possui apólice
                        "
                      >
                        <i class="fas fa-ban"></i>
                        Cancelar apólice nº{{seguro.numeroapolice }}
                      </a>
                    </div>
                    <!--opções seguro-->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="orcarSegIContrato(row)"
                      v-if="
                        !has_seguroAtivo(row.seguroincendio) &&
                        !has_seguroContratando(row.seguroincendio) &&
                        !has_seguroCotando(row.seguroincendio) &&
                        row.seg_deve_contratar == 1 &&
                        ['1', '2'].includes(row.situcontrato_id.toString())
                      "
                    >
                      <i class="fas fa-house-fire"></i>
                      Cotar Seguro Incêndio
                    </a>

                    <a class="dropdown-header"><b>Contrato:</b></a>

                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="editContrato(row)"
                      ><i class="fas fa-user-edit"></i>Editar contrato</a
                    >

                    <a
                      v-if="
                        !has_seguroAtivo(row.seguroincendio) &&
                        !has_seguroContratando(row.seguroincendio) &&
                        !has_seguroCotando(row.seguroincendio) &&
                        ['1', '2'].includes(row.situcontrato_id.toString())
                      "
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="deleteContrato(row)"
                      ><i class="fas fa-trash"></i>Remover Contrato</a
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Exibindo de {{ total ? from + 1 : 0 }} até {{ to }} de um total de
              {{ total }} registros.

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import Vue from "vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      contratos: [],
      preferenciaapis: {
        erp_id: 0,
      },
      navitem_at: 0,
      dashboard: [],
      selectedRows: [],
      sort: "-created_at",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
  mounted() {
    this.getPreferenciaapis();
    this.getDashboard();
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    formatDate(dateStr) {
      const date = new Date(dateStr + "T00:00");
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("pt-BR", options);
    },

    is_vencido(validade) {
      const hoje = new Date();
      hoje.setHours(0, 0, 0, 0);
      validade = new Date(validade + "T00:00");
      return validade < hoje;
    },

    has_seguroAtivo(ListaSeguros) {
      //4=apolice ativa | 15 ativa em periodo de renovacao
      return ListaSeguros.some((seguro) => {
        return (
          ["4", "15"].includes(seguro.seguroistatu.id) &&
          !this.is_vencido(seguro.datafim)
        );
      });
    },
    has_seguroContratando(ListaSeguros) {
      //3=Enviando Proposta | 12 Sendo analisada seguradora
      return ListaSeguros.some((seguro) => {
        return ["3", "12"].includes(seguro.seguroistatu.id);
      });
    },
    has_seguroCotando(ListaSeguros) {
      //1=orçando | 2 Escolha forma de pagamento e contrate | Orçamento expirado
      return ListaSeguros.some((seguro) => {
        return ["1", "2", "17"].includes(seguro.seguroistatu.id);
      });
    },
    getBorderStyle(id) {
      /*                
      Apólice ativa (4) 
      Sendo analisada seguradora (12) 
      Enviando porposta (3) 
      Em periodo de renovação (15)    
      ----                       
      Escolha forma de pagto (2)                             
      Orçamento Expirado (17)                        
      Orçando (1)  
      */
      id = id.toString(); // Garantir que seja string
      if (["2", "17", "15"].includes(id)) {
        return "#fb6340"; //warning
      } else if (id === "4") {
        return "#5e72e4"; //primary
      } else if (["1", "12", "3"].includes(id)) {
        return "#8392ab"; //secondary
      }
      return "#343a40"; // dark
    },
    async AlterarDataInicio(row) {
      if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
        this.$notify({
          type: "danger",
          message: "Você não esta habilitado para alterar a data",
        });
        return;
      }
      try {
        const { value: date } = await swal.fire({
          title: `Data de inicio de vigencia do seguro`,
          input: "date",
          inputValidator: (value) => {
            if (!value) {
              return "Você precisa selecionar uma data!";
            }

            //verifica se é data futura
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            var inicio = new Date(value + "T00:00");
            if (inicio < hoje) {
              return "A data deve ser maior ou igual a hoje!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Salvar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          },
        });

        if (date) {
          var seguroincendio = {
            type: "seguroincendios",
            id: row.id,
            datainicio: date,
            contrato: {
                type: "contratos",
                id: row.contrato_id.toString(),              
              },
            clausulaservico: {
                type: "clausulaservicos",
                id: row.clausulaservico_id.toString(),              
              },
            metodocalculo: {
                type: "metodocalculos",
                id: row.metodocalculo_id.toString(),              
              },
            relationshipNames: ["contrato", "clausulaservico", "metodocalculo"],
          };

          await this.$store.dispatch(
            "seguroincendios/updatedata",
            seguroincendio
          );
          await this.getList();
          this.$notify({
            type: "success",
            message: "Dados enviados para nova cotação.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async Atualizarorcamento(row) {
      try {
        var seguroincendio = {
          type: "seguroincendios",
          id: row.id,
          datainicio: null,
          contrato: {
              type: "contratos",
              id: row.contrato_id.toString(),              
            },
          clausulaservico: {
              type: "clausulaservicos",
              id: row.clausulaservico_id.toString(),              
            },
          metodocalculo: {
              type: "metodocalculos",
              id: row.metodocalculo_id.toString(),              
            },
          relationshipNames: ["contrato", "clausulaservico", "metodocalculo"],
        };

        await this.$store.dispatch(
          "seguroincendios/updatedata",
          seguroincendio
        );
        await this.getList();
        this.$notify({
          type: "success",
          message: "Dados enviados para atualizar cotação.",
        });
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async getList() {
      try {
        //identifica tipo de dados
        var isdata = false;
        var ismoney = false;
        if (this.query) {
          //verifica se é uma dta
          const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
          isdata = datePattern.test(this.query);

          //verifica se é uma moeda
          const currencyPattern = /^-?\d+(?:,\d{3})*(?:\.\d{0,2})?$/;
          ismoney = currencyPattern.test(this.query);
        }
        //cria parametros
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          ...(this.navitem_at == 3 ? { sort: "data_renovacao_externa" } : {}),
          filter: {
            ...(this.dashboard[this.navitem_at] &&
            [this.dashboard[this.navitem_at]["filtro_scope"]] != ""
              ? {
                  [this.dashboard[this.navitem_at]["filtro_scope"]]:
                    this.dashboard[this.navitem_at]["filtro_valor"],
                }
              : {}),
            ...(this.query ? { codigo: this.query } : {}),
            ...(this.query && isdata ? { datainicio: this.query } : {}),
            ...(this.query && isdata ? { valorlocacao: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include:
            "imovel,seguroistatu,seguroincendio,seguroincendio.seguroistatu",
        };
        await this.$store.dispatch("contratos/list", params).then(() => {
          this.contratos = this.$store.getters["contratos/list"];
          this.total = this.$store.getters["contratos/listTotal"];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    async getPreferenciaapis() {
      try {
        let params = {};

        await this.$store.dispatch("preferencias/list", params).then(() => {
          this.preferenciaapis = this.$store.getters["preferencias/list"];
          this.preferenciaapis = this.preferenciaapis[0];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    async getDashboard() {
      try {
        //Topicos
        await this.$store.dispatch("contratos/dashboard").then(() => {
          this.dashboard = this.$store.getters["contratos/dashboard"];
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    orcarSegIContrato(row) {
      this.$router.push({
        name: "Add Seguroincendio",
        params: { contrato: row.id },
      });
    },

    contratarSeguroincendio(row) {
      this.$router.push({
        name: "Add Seguroicontratar",
        params: { seguroincendio_id: row.id },
      });
    },

    async deleteSeguroincendio(row) {
      if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
        this.$notify({
          type: "danger",
          message:
            "Você não esta habilitado para alterar dados de seguroincendios.",
        });
        return;
      }
      try {
        const confirmation = await swal.fire({
          title: `Cancelar esta apólice?`,
          type: "question",
          input: "text",
          inputPlaceholder: "Digite o Motivo do cancelamento",
          inputAttributes: {
            maxlength: "100",
            autocapitalize: "off",
          },
          inputValidator: (value) => {
            if (!value || value.length < 5) {
              return "Você precisa digitar um motivo!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Sim, Cancelar!",
          cancelButtonText: "Não, Manter Apólice",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          },
        });

        if (confirmation.isConfirmed === true) {
          var seguroicancelar = {
            type: "seguroicancelars",
            motivo: confirmation.value,
            seguroincendio: {
              type: "seguroincendios",
              id: row.id,
            },
            relationshipNames: ["seguroincendio"],
          };

          await this.$store.dispatch("seguroicancelars/add", seguroicancelar);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Apólice Enviada para Cancelamento.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    editContrato(row) {
      this.$router.push({
        name: "Edit Contrato",
        params: { id: row.id },
      });
    },

    listaOrcamento(row) {
      this.$router.push({
        name: "List Seguroincendios",
        params: { contrato_filter: row.id },
      });
    },
    async deleteContrato(row) {
      try {
        const confirmation = await swal.fire({
          title: `Deseja deletar este Contrato?`,
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Sim, deletar!",
          cancelButtonText: "Não, manter",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          },
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("contratos/destroy", row.id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Contrato removido com sucesso.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "Este contrato esta associado com outros registros",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },

    async baixarArquivoProposta(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch(
          "seguroincendios/baixarArquivo",
          ["proposta", row.numeroproposta]
        );
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: "application/pdf" });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, "_blank");
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
    async baixarArquivo(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch(
          "seguroincendios/baixarArquivo",
          ["apolice", row.numeroapolice]
        );
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: "application/pdf" });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, "_blank");
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
    async baixarContrato(row) {
      var txtinput = "Digite o Código do Contrato";
      var txttitulo = "Código do Contrato";
      if (this.preferenciaapis.erp_id == 2) {
        //imoview
        txtinput = "Digite o Código do Imóvel";
        txttitulo = "Código do Imóvel";
      }

      try {
        const confirmation = await swal.fire({
          title: txttitulo,
          type: "question",
          input: "text",
          inputPlaceholder: txtinput,
          inputAttributes: {
            maxlength: "100",
            autocapitalize: "off",
          },
          inputValidator: (value) => {
            if (!value || value.length < 3) {
              return "Você precisa digitar um Código!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Baixar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          },
        });

        if (confirmation.isConfirmed === true) {
          var contratocodbaixar = confirmation.value;

          await this.$store.dispatch("contratos/erpbaixar", contratocodbaixar);
          this.$notify({
            type: "success",
            message: "Baixando Contrato, por favor aguarde...",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, Algo deu errado!",
        });
      }
    },
    addContrato() {
      this.$router.push({ name: "Add Contrato" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
