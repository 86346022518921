var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Lista de Locações")]),(
                _vm.dashboard[this.navitem_at] &&
                [this.dashboard[this.navitem_at]['descricao']] != ''
              )?_c('h4',[_c('small',[_vm._v("Exibindo "+_vm._s(this.dashboard[this.navitem_at]["descricao"]))])]):_vm._e()]),_c('div',{staticClass:"col-6 text-right"},[(
                _vm.preferenciaapis.erp_id != 0 && _vm.preferenciaapis.erp_id != null
              )?_c('base-button',{attrs:{"type":"info","icon":"","size":"sm"},on:{"click":function($event){return _vm.baixarContrato()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-code"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Baixar do "),(_vm.preferenciaapis.erp_id == 1)?_c('span',[_vm._v("Superlogica")]):(_vm.preferenciaapis.erp_id == 2)?_c('span',[_vm._v("Imoview")]):_c('span',[_vm._v("ERP")])])]):_vm._e(),_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.addContrato()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-house-user"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Nova Locação")])])],1)]),_c('div',{staticClass:"container"},[_vm._l((_vm.dashboard),function(alerta,index){return [(alerta.is_warning == true && alerta.result_qtd > 0)?_c('base-alert',{key:index,staticClass:"mt-4",attrs:{"dismissible":"","type":"warning","icon":"fas fa-triangle-exclamation fa-3x"}},[_c('H4',[_vm._v("ATENÇÃO!")]),_c('p',[_vm._v(_vm._s(alerta.txt_warning))]),(_vm.navitem_at != index)?_c('base-button',{staticClass:"ml-0",attrs:{"type":"secondary","size":"sm"},on:{"click":function($event){_vm.navitem_at = index;
                  _vm.getList();}}},[_vm._v(" Visualizar ")]):_c('div',[_c('i',[_vm._v("Exibindo Contratos na listagem abaixo")])])],1):_vm._e()]})],2)]),_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',[_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":"Pesquisar...","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),[_c('ul',{staticClass:"nav nav-tabs nav-fill"},_vm._l((_vm.dashboard),function(item,index){return _c('li',{key:index,staticClass:"nav-item"},[(item.is_warning == false || _vm.navitem_at == index)?_c('a',{class:[
                  _vm.navitem_at == index ? 'active' : '',
                  'nav-link',
                  item.result_qtd > 0 || item.style == 'todos'
                    ? ''
                    : 'disabled' ],attrs:{"href":"#"},on:{"click":function($event){_vm.navitem_at = index;
                  _vm.getList();}}},[(item.result_qtd > 0)?_c('badge',{staticClass:"mr-2",attrs:{"type":item.style}},[_vm._v(_vm._s(item.result_qtd)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.title))],1):_vm._e()])}),0)],_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.contratos},on:{"sort-change":_vm.sortChange}},[(_vm.loading)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":"Código","min-width":"90px","prop":"codigo","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',{style:({
                  textDecoration: !['1', '2'].includes(
                    row.situcontrato_id.toString()
                  )
                    ? 'line-through'
                    : 'none',
                })},[_vm._v(" "+_vm._s(row.codigo)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Imóvel","min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('div',[(
                    !['1', '2'].includes(row.situcontrato_id.toString()) &&
                    (_vm.has_seguroAtivo(row.seguroincendio) ||
                      _vm.has_seguroContratando(row.seguroincendio))
                  )?_c('span',{staticClass:"badge badge-danger"},[_vm._v("CONTRATO ENCERRADO")]):_vm._e(),(row.imovel)?_c('span',{style:({
                    textDecoration: !['1', '2'].includes(
                      row.situcontrato_id.toString()
                    )
                      ? 'line-through'
                      : 'none',
                  })},[_vm._v(" "+_vm._s(row.imovel.codimovel ? row.imovel.codimovel : "##")+": "+_vm._s(row.imovel.logradouro ? row.imovel.logradouro : "")+" "),(
                      row.imovel.numero != 0 &&
                      row.imovel.numero != '' &&
                      row.imovel.numero != null
                    )?_c('span',[_vm._v(" nº "+_vm._s(row.imovel.numero)+" ")]):_vm._e(),(
                      row.imovel.complemento != '' &&
                      row.imovel.complemento != null
                    )?_c('span',[_vm._v(" ("+_vm._s(row.imovel.complemento)+") ")]):_vm._e(),_vm._v(" - "+_vm._s(row.imovel.bairro))]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"label":"Seguro","min-width":"125px","prop":"seguroistatu.status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [(
                  !['1', '2'].includes(row.situcontrato_id.toString()) &&
                  !_vm.has_seguroAtivo(row.seguroincendio) &&
                  !_vm.has_seguroContratando(row.seguroincendio)
                )?_c('span',{staticClass:"badge badge-danger"},[_vm._v("CONTRATO ENCERRADO")]):_vm._e(),(
                  row.seg_deve_contratar == 0 &&
                  ['1', '2'].includes(row.situcontrato_id.toString())
                )?_c('span',{staticClass:"badge badge-danger"},[_vm._v(" Seguro não deve ser Contratado ")]):_vm._e(),_c('div',[_c('div',{staticClass:"ml-0"},[[_c('div',_vm._l((row.seguroincendio),function(seguro,index){return _c('div',{key:index},[(
                            ['18'].includes(seguro.seguroistatu_id.toString())
                          )?_c('div',{staticClass:"ml-0"},[_c('div',[_c('b',[_vm._v(_vm._s(seguro.seguroistatu.status))])]),_c('div',[_c('small',[_vm._v("Ativa até "+_vm._s(_vm.formatDate(row.data_renovacao_externa)))])]),_c('div',[_c('small',[_c('div',[_c('b',[_vm._v("*Informações provenientes do ERP")])]),_c('div',[_c('i',[_vm._v("*A existencia da apólice e sua vigencia deve ser verificada.")])])])])]):_vm._e(),(
                            ['1', '4', '3', '12', '15', '2', '17'].includes(
                              seguro.seguroistatu_id.toString()
                            )
                          )?_c('div',{staticClass:"my-4 p-2 rounded",style:({
                            border:
                              '1px solid ' +
                              _vm.getBorderStyle(seguro.seguroistatu_id),
                          })},[_c('div',[_c('small',{style:({ margin: '.1rem' })},[(seguro.numeroapolice != 0)?_c('b',[_vm._v("APÓLICE "+_vm._s(seguro.numeroapolice))]):(seguro.numeroproposta != 0)?_c('b',[_vm._v("PROPOSTA "+_vm._s(seguro.numeroproposta))]):(seguro.numeroCalculo != 0)?_c('b',[_vm._v("ORÇAMENTO "+_vm._s(seguro.numeroCalculo))]):(
                                  ['1'].includes(
                                    seguro.seguroistatu_id.toString()
                                  )
                                )?_c('b',[_vm._v("ORÇANDO")]):_vm._e()])]),_c('div',[_c('small',[_vm._v(" "+_vm._s(seguro.numeroapolice != 0 ? "Ativa" : "Periodo")+" "),(
                                  !_vm.is_vencido(seguro.datainicio) ||
                                  ['1', '2', '17'].includes(
                                    seguro.seguroistatu_id.toString()
                                  )
                                )?_c('span',[_vm._v(" de "+_vm._s(_vm.formatDate(seguro.datainicio)))]):_vm._e(),_vm._v(" até "+_vm._s(_vm.formatDate(seguro.datafim))+" ")])]),(
                              ['4', '15'].includes(
                                seguro.seguroistatu_id.toString()
                              )
                            )?_c('div',[_c('small',[_vm._v(" "+_vm._s(seguro.parcela_qtd)+"x de R$ "+_vm._s(seguro.parcela_valor)+" ")])]):_vm._e(),_c('div',[(seguro.apolicepdf == 1)?_c('span',{staticClass:"badge badge-default",style:({ margin: '.1rem', cursor: 'pointer' }),attrs:{"content":"Baixar Apólice"},on:{"click":function($event){return _vm.baixarArquivo(seguro)}}},[_c('i',{staticClass:"fas fa-file-pdf"}),_vm._v(" Baixar Apólice "+_vm._s(seguro.numeroapolice)+" ")]):(seguro.propostapdf == 1)?_c('span',{staticClass:"badge badge-warning",style:({ margin: '.1rem', cursor: 'pointer' }),attrs:{"content":"Baixar Apólice"},on:{"click":function($event){return _vm.baixarArquivoProposta(seguro)}}},[_c('i',{staticClass:"fas fa-file-pdf"}),_vm._v(" Baixar Proposta ")]):_vm._e(),(
                                ['2', '15'].includes(
                                  seguro.seguroistatu.id
                                ) &&
                                !_vm.is_vencido(seguro.validade) &&
                                !_vm.is_vencido(seguro.datainicio)
                              )?_c('div',{staticClass:"ml-0"},[_c('base-button',{staticClass:"ml-0 mt-2",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.contratarSeguroincendio(seguro)}}},[_c('i',{staticClass:"fas fa-handshake"}),_vm._v("Clique para Contratar ")])],1):(
                                ['2', '17'].includes(
                                  seguro.seguroistatu.id
                                ) && _vm.is_vencido(seguro.datainicio)
                              )?_c('div',{staticClass:"ml-0 mt-2"},[_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.AlterarDataInicio(seguro)}}},[_c('i',{staticClass:"fas fa-calendar"}),_vm._v("Alterar data Inicial ")])],1):(
                                ['2', '17'].includes(
                                  seguro.seguroistatu.id
                                ) &&
                                _vm.is_vencido(seguro.validade) &&
                                !_vm.is_vencido(seguro.datainicio)
                              )?_c('div',{staticClass:"ml-0 mt-2"},[_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.Atualizarorcamento(seguro)}}},[_c('i',{staticClass:"fas fa-house-fire"}),_vm._v("Atualizar Orçamento ")])],1):(
                                (row.seg_deve_contratar == 0 ||
                                ['3', '4'].includes(row.situcontrato_id.toString())) &&
                                ['4', '15'].includes(
                                  seguro.seguroistatu.id
                                ) && //ativa ou em renovação
                                !_vm.is_vencido(seguro.datafim) && //esta vigente
                                seguro.numeroapolice != 0 //possui apólice
                              )?_c('div',{staticClass:"ml-0 mt-2"},[_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.deleteSeguroincendio(seguro)}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v("Cancelar Apólice ")])],1):_vm._e()])]):(
                            !['5'].includes(
                              seguro.seguroistatu_id.toString()
                            ) &&
                            ['1', '2'].includes(
                              row.situcontrato_id.toString()
                            ) &&
                            row.seg_deve_contratar == 1
                          )?_c('div',[_vm._v(" "+_vm._s(seguro.seguroistatu.status)+" ")]):_vm._e()])}),0)],(
                      !_vm.has_seguroAtivo(row.seguroincendio) &&
                      !_vm.has_seguroContratando(row.seguroincendio) &&
                      !_vm.has_seguroCotando(row.seguroincendio) &&
                      row.seg_deve_contratar == 1 &&
                      ['1', '2'].includes(row.situcontrato_id.toString())
                    )?_c('div',{staticClass:"ml-0"},[_c('base-button',{staticClass:"ml-0",attrs:{"type":"warning","size":"sm"},on:{"click":function($event){return _vm.orcarSegIContrato(row)}}},[_c('i',{staticClass:"fas fa-house-fire"}),_vm._v("Clique para Cotar ")])],1):_vm._e()],2)])]}}])}),_c('el-table-column',{attrs:{"min-width":"50px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                      (//cotar
                        !_vm.has_seguroAtivo(row.seguroincendio) &&
                      !_vm.has_seguroContratando(row.seguroincendio) &&
                      !_vm.has_seguroCotando(row.seguroincendio) &&
                      row.seg_deve_contratar == 1 &&
                      ['1', '2'].includes(row.situcontrato_id.toString())
                      ) || (//cancelar
                        _vm.has_seguroAtivo(row.seguroincendio)
                      )
                    )?_c('a',{staticClass:"dropdown-header"},[_c('b',[_vm._v("Seguro:")])]):_vm._e(),_vm._l((row.seguroincendio),function(seguro,index){return _c('div',{key:index},[(
                        ['4', '15'].includes(seguro.seguroistatu.id) && //ativa ou em renovação
                        !_vm.is_vencido(seguro.datafim) && //esta vigente
                        seguro.numeroapolice != 0 //possui apólice
                      )?_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteSeguroincendio(seguro)}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Cancelar apólice nº"+_vm._s(seguro.numeroapolice)+" ")]):_vm._e()])}),(
                      !_vm.has_seguroAtivo(row.seguroincendio) &&
                      !_vm.has_seguroContratando(row.seguroincendio) &&
                      !_vm.has_seguroCotando(row.seguroincendio) &&
                      row.seg_deve_contratar == 1 &&
                      ['1', '2'].includes(row.situcontrato_id.toString())
                    )?_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.orcarSegIContrato(row)}}},[_c('i',{staticClass:"fas fa-house-fire"}),_vm._v(" Cotar Seguro Incêndio ")]):_vm._e(),_c('a',{staticClass:"dropdown-header"},[_c('b',[_vm._v("Contrato:")])]),_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editContrato(row)}}},[_c('i',{staticClass:"fas fa-user-edit"}),_vm._v("Editar contrato")]),(
                      !_vm.has_seguroAtivo(row.seguroincendio) &&
                      !_vm.has_seguroContratando(row.seguroincendio) &&
                      !_vm.has_seguroCotando(row.seguroincendio) &&
                      ['1', '2'].includes(row.situcontrato_id.toString())
                    )?_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteContrato(row)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v("Remover Contrato")]):_vm._e()],2)],1)]}}])})],1)],2),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Exibindo de "+_vm._s(_vm.total ? _vm.from + 1 : 0)+" até "+_vm._s(_vm.to)+" de um total de "+_vm._s(_vm.total)+" registros. "),(_vm.selectedRows.length)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }